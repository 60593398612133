<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('czhi.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
	<div class="nav_wrap">
		<div class="nav_item" :class="{active_nav:ordertype==1}" @click="selorder(1)">在线充值</div>
		<div class="nav_item" :class="{active_nav:ordertype==2}" @click="selorder(2)">USDT充值</div>
	</div>
    <div class="maincontent" style="background: #181e34;">
		<van-row v-if="ordertype==1">
			<select id="unitDe" type="text" v-model="curmoneytype">
				<option value="">请选择货币单位</option>
				<option :value="item.id" v-for="(item,index) in moneytype" :key="index">
					{{item.bieming}}
				</option>
			</select>
			<div class="cell_item">
				<div class="cell_item_left">
					<span>收款姓名</span>
					<span id="name">充值请先联系在线客服</span>
				</div>
				<i data-value="充值请先联系在线客服" class="iconfont1 iconCopy  icon-fuzhi"></i>
			</div>
			<div class="cell_item">
				<div class="cell_item_left">
					<span>银行名称</span>
					<span id="name">充值请先联系在线客服</span>
				</div>
				<i data-value="充值请先联系在线客服" class="iconfont1 iconCopy  icon-fuzhi"></i>
			</div>
			<div class="cell_item">
				<div class="cell_item_left">
					<span>支行名称</span>
					<span id="name">充值请先联系在线客服</span>
				</div>
				<i data-value="充值请先联系在线客服" class="iconfont1 iconCopy  icon-fuzhi"></i>
			</div>
			<div class="cell_item">
				<div class="cell_item_left">
					<span>银行卡号</span>
					<span id="name">充值请先联系在线客服</span>
				</div>
				<i data-value="充值请先联系在线客服" class="iconfont1 iconCopy  icon-fuzhi"></i>
			</div>
			<div class="money_list">
				<div class="money_item" v-for="(item,index) in moneylist" :key="index"
				:class="{active_money:curmoney==item}"
				@click="selmoney(item)"
				>
					{{item}}
				</div>
				<div class="money_item">
					<input placeholder="其他金额" @focus="resetmoney" @input="changemoney" v-model="diymoney"/>
				</div>
			</div>
			  <div class="form_item">
				  <input placeholder="请输入充值姓名" v-model="bankform.wallet_address"/>
			  </div>
			  <div class="current">
				  <span class="ydyu">≈</span>
				  <span class="showMoney">{{getmoney()}}</span>
				  <span class="danwei">{{getcurmoneytype.danwei||'¥'}}</span>
			 </div>
		  <div class="lable_title">{{$t('czhi.upload')}}</div>
          <van-row class="message" v-if="false">{{ $t('czhi.upload') }}</van-row>
          <van-row class="uploading-image">
            <van-uploader :beforeRead="beforeRead" :after-read="afterRead">
              <img
                :src="uploadimg ? uploadimg : ''"
                ref="uploadImg"
                alt=""
                v-if="uploadimg"
                style="max-width:100%;max-height:13rem;text-align:center"
              />
            </van-uploader>
          </van-row>
			<van-button type="primary" style="width: 90%;margin: 30px auto;background: #ff571e;border: none;" 
				@click="saveorder">
				提交
			</van-button>
		</van-row>
		
		<van-row v-if="ordertype==2">
			<payUsdt/>
		</van-row>
    </div>
	<tabbar curpage="2"/>
  </div>
</template>

<script>
	import tabbar from '../../components/tabbar.vue';
	import payUsdt from '../../components/pay_usdt.vue';
export default {
	components:{payUsdt,tabbar},
  data() {
    return {
		curmoneytype:"1",
		moneytype:[],
		moneylist:[100,1000,10000,50000,100000],
      loading: false,
	  ordertype:1,
	  curmoney:100,
	  diymoney:'',
	  bankform:{
		  money:100,
		  wallet_address:""
	  },
	  uploadimg:""
    }
  },
  computed:{
	getcurmoneytype(){
		if(this.curmoneytype){
			let arr=this.moneytype.filter(item=>{return item.id==this.curmoneytype});
			if(arr.length!=0){
				return arr[0];
			}else{
				return {};
			}
		}else{
			return {};
		}
		
	}  
  },
  created() {
    this.getcointype();
  },
  mounted() {},
  methods: {
	  getmoney(){
		if(this.getcurmoneytype.huilv){
			if(this.bankform.money){
				return (+this.bankform.money*this.getcurmoneytype.huilv).toFixed(2);
			}else{
				return '0.00';
			}
		}else{
			return '0.00';
		}
	  },
	  changemoney(){
		  this.curmoney='';
		  this.bankform.money=this.diymoney;
	  },
	  resetmoney(){
		this.curmoney=''; 
		 this.bankform.money=this.diymoney;
	  },
	  selmoney(item){
		  this.diymoney='';
		this.curmoney=item; 
		this.bankform.money=this.curmoney;
	  },
	  async saveorder(){
		  if (this.bankform.wallet_address=='') {
		    return this.$toast.fail('请输入姓名')
		  }
		  let recharge_num = +this.getmoney();
		  if (recharge_num=='') {
		    return this.$toast.fail('请输入金额')
		  }
		  if (!this.getcurmoneytype.id) {
		    return this.$toast.fail('请选择货币单位')
		  }
		  if (!this.uploadimg) {
			this.$toast.fail(this.$t('czhi.upload'))
			return false
		  }
		  const postform = {
		    product_type: this.getcurmoneytype.bieming,
		    product_type_id: this.getcurmoneytype.id,
		    wallet_info: 3,
		    wallet_address: this.bankform.wallet_address,
		    recharge_image: this.uploadimg,
		    recharge_num: recharge_num
		  }
		  const { data } = await this.$http.post('/home/user/recharge', postform)
		  if (data) {
		    if (data.code === 200) {
		      this.uploadimg = ''
		      this.form = {}
		      this.$toast.success(this.$t('czhi.submitok'))
		    } else {
		      this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
		    }
		  }
	  },
    beforeRead(file) {
      if (
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/gif'
      ) {
        this.$toast(
          this.getlang('图片格式不正确', localStorage.getItem('lang'))
        )
        return false
      }
      return true
    },
    async afterRead(file) {
      const param = new FormData()
      param.append('file', file.file)
      const { data } = await this.$http.post('/home/user/uploadimg', param)
      if (data) {
        this.uploadimg = data.data.url
      }
    },
	  todetail(item){
		this.$router.push("/orderdetail/"+item.id);  
	  },
	  selorder(type){
		this.ordertype=type;  
		this.getOrders();
	  },
	  async getcointype(){
		  const { data } = await this.$http.get('/home/trade/gethuobi')
		  if (data) {
		    if (data.code === 200) {
		      this.moneytype = data.data;
			  let moren = this.moneytype.filter((item)=>{
				  return item.moren==1;
			  });
			  if(moren.length>0){
				  this.curmoneytype=moren[0].id;
			  }else{
				  this.curmoneytype=this.moneytype[0].id;
			  }
		    }
		  }
	  },
    // 创新记录
    async getOrders() {
      const { data } = await this.$http.get('/home/trade/get_orders')
      if (data) {
        if (data.code === 200) {
          this.orders = data.data
        }
      }
    },
    // 充值记录
    async getRecharges() {
      const { data } = await this.$http.get('/home/trade/get_recharges')
      if (data) {
        if (data.code === 200) {
          this.recharges = data.data
        }
      }
    },
    // 提币记录
    async getWithdraws() {
      const { data } = await this.$http.get('/home/trade/get_withdraws')
      if (data) {
        if (data.code === 200) {
          this.withdraws = data.data
        }
      }
    },
    // 提币记录
    async getTransfers() {
      const { data } = await this.$http.get('/home/trade/get_transfers')
      if (data) {
        if (data.code === 200) {
          this.transfers = data.data
        }
      }
    },
    change(name) {
      console.log(name)
      switch (name) {
        case 'tab2':
          this.getOrders()
          break
        case 'tab1-1':
          this.getRecharges()
          break
        case 'tab1-2':
          this.getWithdraws()
          break
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .uploading-image {
    height: 13.33333rem;
    width: 90%;
    margin: 2% 5% 5% 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.02667rem dashed #ff571e;
	border-radius: 0.4rem;
    .van-uploader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
	  /deep/.van-uploader__upload-icon{
		  font-size: 60px;
	  }
      /deep/.van-uploader__wrapper {
        width: 100%;
        height: 100%;
        justify-content: center;
      }
      /deep/.van-uploader__upload {
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: transparent;
        color: #fff;
      }
    }
  }
	.lable_title{
		font-size: 1rem;
		text-align: left;
		padding: 0.6rem;
		color: #cad0e6;
	}
	.current{
		text-align: left;
		color: #ff571e;
		padding-left: 20px;
		padding-top: 20px;
		.ydyu{
			
		}
		.showMoney{
			font-size: 2.5rem;
		}
		.danwei{
			font-size: 2.5rem;
		}
	}
	.money_list{
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		padding: 10px;
		.money_item{
			width: calc(calc(100% - 20px)/3);
			padding: 12px 0;
			border-radius: 0.5rem;
			color: #ff571e;
			border: 0.026667rem solid #ff571e;
			text-align: center;
			input{
				width: 100%;
				border: none;
				padding-left:10px;
				box-sizing: border-box;
				border-radius: 0.4rem;
				background-color: transparent;
				color: #ff571e;
			}
		}
		.active_money{
			background-color: #ff571e;
			color: #fff;
		}
	}
	.cell_item{
		box-sizing: border-box;
		width: 100%;
		border-bottom: 0.026667rem solid #23293f;
		padding: 1.1rem 0.8rem;
		font-size: 0.826667rem;
		color: #cad0e6;
		display: flex;
		align-items: center;
	}
	.cell_item_left{
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		span:first-child {
		    font-size: 1rem;
		}
	}
	.iconCopy{
		color: #cad0e6;
		font-size: 1.7rem;
	}
	.form_item{
		padding: 0.6rem;
		input{
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			border: 1px solid #ff571e;
			border-radius: 0.4rem;
			background-color: transparent;
			    color: #ff571e;
		}
	}
		.form_cell_item{
			padding: 0.6rem;
			display: flex;
			align-items: center;
			position: relative;
			&::after {
			    content: " ";
			    position: absolute;
			    left: 0;
			    bottom: 0;
			    right: 0;
			    height: 0.026667rem;
			    border-bottom: 0.026667rem solid #e5e5e5;
			    color: #e5e5e5;
			    -webkit-transform-origin: 0 100%;
			    -ms-transform-origin: 0 100%;
			    transform-origin: 0 100%;
			    -webkit-transform: scaleY(0.5);
			    -ms-transform: scaleY(0.5);
			    transform: scaleY(0.5);
			    z-index: 2;
			}
			.form_cell_label{
				width: 115px;
				font-size: 18px;
				color: #8686aa;
				text-align: left;
			}
			input{
				width: 100%;
				flex: 1;
				padding: 20px;
				box-sizing: border-box;
				background-color: transparent;
				color: #8686aa;
				outline: none;
				font-size: 18px;
				border: none;
			}
		}
	#unitDe{
		border: 0.026667rem solid #ff571e;
		height: 3.5rem;
		box-sizing: border-box;
		display: block;
		width: 95%;
		color: #ff571e;
		border-radius: 0.613333rem;
		padding: 0.613333rem;
		margin: 0.413333rem;
		outline: 0;
		-webkit-appearance: none;
		background-color: transparent;
	}
	.info_item{
		color: #cad0e6;
		border: 0.026667rem solid #8686aa;
		width: calc(100% - 0.893333rem);
		margin: auto;
		margin-top: 2.666667rem;
		padding: 0.513333rem 0.4rem;
		border-radius: 0.513333rem;
	}
	.tableTr {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 0.026667rem solid #8686aa;
		min-height: 2.7rem;
		line-height: 2.7rem;
		font-size: 1rem;
	}
	.tableTr:last-child {
		border-bottom: none;
	}
	.tableTd:first-child {
		width: 30%;
		min-width: 30%;
		word-break: break-all;
		border: none;
		text-align: left;
	}
	.tableTd:last-child {
		width: 70%;
		min-width: 70%;
		word-break: break-all;
		    border: none;
			text-align: right;
	}
	.icon-shuaqiaqiapianyinhangqia{
		color: #ff571e;
		font-size: 8rem;
	}
	.red_txt{
		color: #e75671;
	}
	.green_txt{
		color: #28be86;
	}
	.nav_wrap{
		display: flex;
		background-color: #ff571e;
		position: sticky;
		top: 46px;
		z-index: 111;
		.nav_item{
			width: 50%;
			padding: 20px 0;
			text-align: center;
			color: #cad0e6;
		}
		.active_nav{
			color: #ff571e;
			background-color: #181e34;
			border-radius: 0.5rem 0.5rem 0 0;
		}
	}
.maincontent {
  padding: 46px 0;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  /deep/.van-tab {
    font-size: 1.04rem;
  }
  .currency-Increase {
    color: #8686aa;
    line-height: 1.6rem;
    font-size: 0.8rem;
    .currency-time {
      text-align: right;
    }
  }
}
</style>
